import React, { Component } from 'react'
import { Layout } from 'core/components'
import { AAnimate } from 'shared/components/common'
import { ParcelQuotationLayout } from 'modules/parcels/pages'

type CotizarEnvioProps = PageProps
interface CotizarEnvioState {
  mounted: boolean
}

class CotizarEnvio extends Component<CotizarEnvioProps, CotizarEnvioState> {
  state = {
    mounted: false
  }

  componentDidMount() {
    setTimeout(() => {
      this.setState({ mounted: true })
    }, 0)
  }

  render() {
    const {
      pageContext: { locale }
    } = this.props
    const { mounted } = this.state
    return (
      <Layout locale={locale}>
        <AAnimate type="fadeIn" in={mounted}>
          <ParcelQuotationLayout />
        </AAnimate>
      </Layout>
    )
  }
}

export default CotizarEnvio
